import React, { useContext } from 'react';
import {
  AutumnFormValidationIcon,
  AutumnFormValidationIconState,
} from '../AutumnFormValidationIcon';
import { AutumnDatePicker } from '../AutumnDatePicker';
import { AutumnTimePicker } from '../AutumnTimePicker';
import { AutumnCheckbox } from '../AutumnCheckbox';
import { AutumnVerticalSelector } from '../AutumnVerticalSelector';
import { AutumnImageSelector } from '../AutumnImageSelector';
import {
  AutumnBrandSelector,
  AutumnLocationSelector,
  AutumnTagSelector,
  AutumnTreatmentOptionSelector,
  AutumnTreatmentSelector,
  AutumnUserSelector,
} from '../AutumnTagSelector';
import { AutumnPhoneNumberInput } from '../AutumnPhoneNumberInput';
import { AutumnTurnstile } from '../AutumnTurnstile';
import { AutumnDropdown } from '../AutumnDropdown';
import { AutumnInput } from '../AutumnInput';
import { AutumnFormElement } from './types';
import { InternalFormContext } from './context';
import { useOfflineOnline } from '../../../../features/OnlineOffline/hooks/useOfflineOnline';
import { AutumnColorPicker } from '../AutumnColorPicker';
import { AutumnHorizontalSelectorField } from '../AutumnHorizontalSelector';

export function AutumnFormSectionElementInput<T>({ elem }: { elem: AutumnFormElement<T> }) {
  const { loading, onChange, state, validationMap } = useContext(InternalFormContext);

  const { online } = useOfflineOnline();

  const disabled = !online || loading;

  const validationState = (): AutumnFormValidationIconState => {
    if (validationMap?.[elem.key as string]?.valid) return 'valid';

    if (validationMap?.[elem.key as string]?.valid === false) return 'invalid';

    return 'none';
  };

  const isInvalid = validationMap && validationState() === 'invalid';

  const renderElement = () => {
    if (elem.type === 'element') return elem.element;

    if (elem.type === 'custom' && typeof elem.custom === 'function') {
      return elem.custom(onChange, disabled);
    }

    if (elem.type === 'color') {
      return (
        <AutumnColorPicker
          label={elem.label}
          value={state?.[elem.key as string] as string}
          onChange={(e) => onChange(elem.key as string, e)}
        />
      );
    }

    if (elem.type === 'date') {
      return (
        <AutumnDatePicker
          key={elem.key as string}
          disabled={disabled}
          value={state?.[elem.key as string] as string}
          label={elem.label}
          startWithYear={elem.key === 'dob'}
          onChange={(e) => onChange(elem.key as string, e)}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'time') {
      return (
        <AutumnTimePicker
          key={elem.key as string}
          disabled={disabled}
          value={state?.[elem.key as string] as string}
          label={elem.label}
          onChange={(e) => onChange(elem.key as string, e)}
          timezones={elem.timezones}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'checkbox') {
      return (
        <AutumnCheckbox
          key={elem.key as string}
          disabled={disabled}
          localeKey={elem.label}
          isChecked={state?.[elem.key as string] as boolean}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
        />
      );
    }

    if (elem.type === 'verticalSelector') {
      return (
        <AutumnVerticalSelector
          localeKey={elem.label}
          options={elem?.pickerOptions ?? []}
          hideUnselected={(elem?.pickerOptions ?? []).length > 4}
          onChange={(e: string) => onChange(elem.key as string, e)}
          selectedOption={state?.[elem.key as string] as string}
          disabled={disabled}
        />
      );
    }

    if (elem.type === 'horizontalSelector') {
      return (
        <AutumnHorizontalSelectorField
          label={elem.label}
          options={elem?.pickerOptions ?? []}
          onChange={(e: string) => onChange(elem.key as string, e)}
          selectedOption={state?.[elem.key as string] as string}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'image') {
      return (
        <AutumnImageSelector
          onUpload={(e) => onChange(elem.key as string, e)}
          src={state?.[elem.key as string] as string}
          disabled={disabled}
          label={elem.label}
          explainer={elem.explainer}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'picker') {
      return (
        <AutumnTagSelector
          label={elem.label}
          options={elem?.pickerOptions ?? []}
          explainer={elem.explainer}
          allowRemove={elem.type === 'picker'}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          singleSelectionOnly={elem.pickerSingleSelect}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'phone') {
      return (
        <AutumnPhoneNumberInput
          disabled={disabled}
          label={elem.label}
          value={state?.[elem.key as string] as string}
          initialCountry={elem.phoneCountry}
          isInvalid={isInvalid}
          onChange={(newPhone: string) => onChange(elem.key as string, newPhone)}
          rightElement={validationMap && <AutumnFormValidationIcon state={validationState()} />}
        />
      );
    }

    if (elem.type === 'usersSelector') {
      return (
        <AutumnUserSelector
          label={elem.label}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          singleSelectionOnly={elem.pickerSingleSelect}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'treatmentSelector') {
      return (
        <AutumnTreatmentSelector
          label={elem.label}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          singleSelectionOnly={elem.pickerSingleSelect}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'treatmentOptionSelector') {
      return (
        <AutumnTreatmentOptionSelector
          label={elem.label}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          singleSelectionOnly={elem.pickerSingleSelect}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'locationSelector') {
      return (
        <AutumnLocationSelector
          label={elem.label}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          singleSelectionOnly={elem.pickerSingleSelect}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'brandSelector') {
      return (
        <AutumnBrandSelector
          label={elem.label}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          singleSelectionOnly={elem.pickerSingleSelect}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'captcha') {
      return <AutumnTurnstile callback={(token) => onChange(elem.key as string, token)} />;
    }

    if (elem.type === 'dropdown') {
      return (
        <AutumnDropdown
          label={elem.label}
          options={elem?.pickerOptions ?? []}
          onChange={(e) => onChange(elem.key as string, e as string)}
          selectedOption={state?.[elem.key as string] as string}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      );
    }

    if (elem.type === 'input' || elem.type === 'multilineInput') {
      return (
        <AutumnInput
          key={elem.key as string}
          disabled={disabled}
          multiline={elem.type === 'multilineInput'}
          value={state?.[elem.key as string] as string}
          label={elem.label}
          explainer={elem.explainer}
          onChange={(e: string) => onChange(elem.key as string, e)}
          isInvalid={isInvalid}
          rightElement={validationMap && <AutumnFormValidationIcon state={validationState()} />}
        />
      );
    }

    throw new Error('Element not defined');
  };

  return (
    <>
      {renderElement()}
      {elem.footer}
    </>
  );
}
