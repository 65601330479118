import { createTheme } from '@shopify/restyle';

const themeCurrent: 'gray' | 'purple' = 'purple';

const palette = {
  blue: '#0097e6',

  gray: 'rgba(0, 0, 0, 0.05)',
  grayText: 'rgba(0, 0, 0, 0.4)',
  'gray.200': 'rgba(0, 0, 0, 0.3)',
  'gray.100': 'rgba(0, 0, 0, 0.10)',
  'gray.50': 'rgba(0, 0, 0, 0.05)',
  'gray.25': 'rgba(0, 0, 0, 0.025)',
  'gray.10': 'rgba(0, 0, 0, 0.010)',
  'coolGray.300': 'rgba(0, 0, 0, 0.05)',

  badgeGood: '#2ecc71',
  badgeInfo: '#ecf0f1',
  badgeWarning: '#f1c40f',
  badgeError: '#e74c3c',

  brightGreen: '#2ecc71',

  red: '#CC4A2B',
  redBright: '#ff542c',

  forestGreen: '#324440',
  green: '#234932',
  tope: '#F0E4D6',
  yellow: '#FFBC16',
  lightgray: '#F0E4D6',
  lightgrayAlpha: 'rgba(240,228,214,0.2)',

  lightGreen: '#7FA356',
  purple: '#5C1034',

  black: '#222220',
  blackAlpha: 'rgba(34,34,32,0.5)',
  purpleAlpha: 'rgba(0,0,0,0.015)',

  neutral: '#F0E4D6',

  white: '#ffffff',
  whiteAlpha: 'rgba(255,255,255,0.05)',

  transparent: 'transparent',

  appointment: 'rgba(204,74,43,0.2)',

  messagingBubble: '#0c79ec',
  messagingBubbleAlt: 'rgb(95 95 95)',
  messagingBubbleHeader: 'black',
  messagingBubbleText: 'white',
  messagingBubbleFooter: 'rgb(113, 113, 122)',
};

const theme = createTheme({
  colors: {
    ...palette,
    buttonPrimary: palette.green,
    buttonOutline: themeCurrent === 'purple' ? palette.white : palette.white,
  },
  spacing: {
    0: 0,
    1: 2,
    2: 8,
    3: 8,
    4: 10,
    5: 10,
    6: 10,
    xs: 4,
    s: 8,
    ms: 12,
    m: 16,
    l: 24,
    xl: 40,
  },
  zIndices: undefined,
  borderRadii: undefined,
  buttonVariants: {
    defaults: {
      height: 35,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'buttonPrimary',
      borderRadius: 4,
    },
    primary: {
      height: 35,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'buttonPrimary',
      borderRadius: 4,
    },
    danger: {
      height: 35,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'red',
      borderRadius: 4,
    },
    create: {
      height: 35,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'green',
      borderRadius: 4,
    },
    ghost: {
      height: 33,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'white',
      borderWidth: 1,
      borderColor: 'white',
      borderRadius: 5,
    },
    outline: {
      height: 33,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'buttonOutline',
      borderWidth: 1,
      borderColor: 'gray',
      borderRadius: 5,
    },
    solid: {
      height: 33,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'buttonPrimary',
      borderWidth: 1,
      borderColor: 'buttonPrimary',
      borderRadius: 5,
    },
    link: {
      height: 30,
      px: 4,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'transparent',
      borderWidth: 0,
    },
    inLineLink: {
      height: 24,
      px: 0,
      justifyContent: 'center',
      alignItems: 'center',
      bg: 'transparent',
      borderWidth: 0,
    },
  },
  textVariants: {
    logo: {
      fontFamily: 'Cormorant Infant',
      fontSize: 33,
      color: 'white',
    },
    header: {
      fontFamily: 'Mulish',
      fontWeight: 'bold',
      fontSize: 22,
      color: 'black',
    },
    subheader: {
      fontFamily: 'Mulish',
      fontWeight: 'bold',
      fontSize: 15,
      color: 'black',
    },
    defaults: {
      fontFamily: 'Mulish',
      fontSize: 13,
      color: 'black',
    },
    bold: {
      fontFamily: 'Mulish',
      fontSize: 13,
      color: 'black',
      fontWeight: 'bold',
    },
    small: {
      fontFamily: 'Mulish',
      fontSize: 12,
      color: 'black',
    },
    xsmall: {
      fontFamily: 'Mulish',
      fontSize: 10,
      color: 'black',
    },
  },
});

export type Theme = typeof theme;
export default theme;
