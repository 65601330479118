// Define localisations here
import { Customer_Card_Type } from '../../../__generatedtypes__/graphql';

export const CustomerPatientClientGuest: string = 'Customer';

export const enLocalKeys = {
  ExpiryWPlaceholder: 'Expiry {{Exp}}',
  ReferenceWPlaceholder: 'Reference {{Ref}}',
  IntervalWPlaceholder: 'Interval {{Interval}}',
  RepeatsWPlaceholder: 'Repeats {{Repeats}}',
  InstructionsWPlaceholder: 'Instructions {{Instructions}}',
  PBS: 'PBS',
  Private: 'Private',
  '1': '1',
  '2': '2',
  AnyResources: 'Any of the selected resources must be available to book',
  AllResources: 'All selected resources must be available to book',
  CreateNoteInstructions:
    'Type your notes here. If using AI transcribing typed notes will be added to the generated note',
  Call: 'Call',
  Calling: 'Calling',
  IncomingCall: 'Incoming Call',
  OutgoingCall: 'Called',
  ERXCreating: 'Creating',
  ERXCeased: 'Ceased',
  ERXActive: 'Active',
  ERXError: 'Error',
  Resummarise: 'Re-Summarise',
  ERXCeasing: 'Ceasing',
  AdoptNote: 'Adopt Note',
  Listening: 'Listening...',
  GeneratedSummaryWait: 'Once your finish speaking your generated summary will appear here',
  GeneratingSummary: 'Generating Summary',
  CancelPrescription: 'Are you sure you wish to delete this prescription?',
  Shortcuts: 'Shortcuts',
  [Customer_Card_Type.AuIhi]: 'IHI',
  [Customer_Card_Type.AuMedicare]: 'Medicare Card',
  [Customer_Card_Type.AuHealthcareCard]: 'Healthcare Card',
  Customer: CustomerPatientClientGuest,
  Done: 'Done',
  eMail: 'Email',
  NewCustomer: `New ${CustomerPatientClientGuest}`,
  TreatmentUpdated: 'Treatment updated',
  TreatmentCreated: 'Treatment created',
  Treatment: 'Treatment',
  AllTreatment: 'All Treatments',
  UseDefault: 'Use Default',
  Duration: 'Duration',
  BookableOnline: 'Bookable online',
  Product: 'Product',
  ProductCreated: 'Product Created',
  Category: 'Category',
  Suppliers_Description: 'Configure the suppliers you can order from',
  DefaultLocation: 'Default Location',
  Location: 'Location',
  Id: 'Id',
  IVR: 'IVR Menu',
  Message: 'Message',
  Voicemail: 'Voicemail',
  SearchBar: 'Search or Ask Autumn AI',
  Admin: 'Admin',
  Marketing: 'Marketing',
  UserLocation: 'User Location',
  Schedule: 'Schedule',
  ScheduleLocation: 'Schedule Location',
  ScheduledAtLocationDate: 'Scheduled at {{locationName}} - {{date}}',
  AddSchedule: '+ Schedule',
  ScheduleAddExplainer: 'Add a new schedule to the appointment book',
  ManageSchedules: 'Manage Schedules',
  NoSchedules: 'No schedules found',
  NewSchedule: 'New Schedule',
  DisableSchedule: 'Disable Schedule',
  EnableSchedule: 'Enable Schedule',
  SchedulesFor: 'Schedules for {{date}}',
  LeaveEmptyForAll: 'Leave empty for all the users enabled treatments',
  BookableTreatments: 'Bookable Treatments',
  RemoteTimezoneDiffers:
    "Location's timezone ({{yourTimezone}}) differs from the remote user's ({{otherTimezone}})",
  TimezoneDiffers:
    "Your timezone ({{yourTimezone}}) differs from the selected location's ({{otherTimezone}})",
  YourTime: '({{userTime}} your time)',
  YourTimeRange: '{{startTime}} - {{endTime}} your time',
  RemoteTimeRange: '{{startTime}} - {{endTime}} for remote user',
  RemoteTimezone: 'Remote Timezone',
  Onsite: 'Onsite',
  Remote: 'Remote',
  Disabled: 'Disabled',
  Brands: 'Brands',
  General: 'General',
  CompleteAllRequiredFields: 'Please complete all required fields',
  BrandsExplainer: 'Brands that the user can access',
  Stock: 'Stock',
  Hi: 'Hi',
  Upload: 'Upload',
  Download: 'Download',
  Delete: 'Delete',
  Change: 'Change',
  PermissionsAdmin:
    'This user is an admin and has full access. You can not adjust the access an admin has',
  PermissionsExplainer:
    'Control what access the user has to Autumn. Trusted devices are pre-approved devices and can be used to help control access on company owned vs personal devices',
  RequiredPermissionMissing: 'You are missing a permission required to view this page.',
  AdminExplainer: 'Admins have full access to all sections of Autumn',
  SearchResults: 'Search Results',
  NoResultsFound: 'No Results Found',
  NoRequestsFound: 'No Requests Found',
  GreatDay: 'Lets have a great day!',
  Settings: 'Settings',
  Reporting: 'Reporting',
  Language: 'Language',
  All: 'All',
  Close: 'Close',
  Clear: 'Clear',
  Signup: 'Sign Up',
  SignupCompletedTitle: 'Check Your eMails!',
  SignupCompletedExplainer:
    'You have successfully signed up. We have sent you an email to {{email}} with a link to activate your account',
  Signin: 'Signin',
  AlreadySignedUp: 'Already signed up? Go to Sign In',
  Timesheet: 'Timesheet',
  DOB: 'DOB',
  DOBwValue: 'DOB {{DOB}} ({{Age}})',
  MobileWValue: 'Mobile {{Mobile}}',
  DynamicValue: '{{DynamicString}}',
  Products: 'Products',
  Treatments: 'Treatments',
  Packages: 'Packages',
  Opportunities: 'Opportunities',
  AVV: 'AVV',
  Mobile: 'Mobile',
  TeamMember: 'Team Member',
  Logout: 'Log Out',
  Date: 'Date',
  DatePickerSingleDate: '{{startDate}}',
  DatePickerRange: '{{startDate}} to {{endDate}}',
  TimePickerDisplay: '{{hours}}:{{minutes}} {{period}}',
  Billing: 'Billing',
  NewNote: 'New Note',
  Notes: 'Notes',
  EnterYourPin: 'Enter Your PIN',
  SetAPIN: 'Set A PIN',
  CompleteLogin: 'Complete Login',
  SetPINExplainer: 'Set a 6 digit PIN number to enable fast logins',
  CouldNotValidatePIN: 'Could not Validate PIN',
  CouldNotSetPIN: 'An error occured setting your PIN, try again',
  Photos: 'Photos',
  Planning: 'Planning',
  StartTime: 'Start Time',
  UserProfileImage: 'Profile Image',
  UserProfileImageExplainer: 'Used in the appointment book and online booking',
  EndTime: 'End Time',
  Break: 'Break',
  Total: 'Total',
  Title: 'Title',
  Save: 'Save',
  Subtitle: 'Subtitle',
  NewPatient: 'New ' + CustomerPatientClientGuest,
  NewPatientCreate: 'Create New ' + CustomerPatientClientGuest,
  OpenPatient: 'Open ' + CustomerPatientClientGuest,
  Day: 'Day',
  Say: 'Say',
  LineSettings: 'Line {{Line}} Settings',
  Ring: 'Ring',
  RingForSeconds: 'Ring For (seconds)',
  RingUsers: 'Ring a list of users in parallel',
  VoicemailDescription: 'Record a message from the caller and notify the users',
  SpeakMessage: 'Play the message to the caller',
  Error_Occurred: 'An error occurred. Please retry',
  Appointments: 'Appointments',
  Results: 'Results',
  Messages: 'Messages',
  eCommerce: 'eCommerce',
  minutesWValue: '{{minutes}} mins',
  FormOffline: 'Offline - You are not able to save this form. Connect to edit & save',
  Inbox: 'Inbox',
  ToDos: 'To Dos',
  Automation: 'Automation',
  Today: 'Today',
  SetToToday: 'Set to Today',
  Week: 'Week',
  WelcomeTitle: 'Welcome to Autumn',
  Details: 'Details',
  Basics: 'Basics',
  Contact: 'Contact',
  EditCustomer: 'Edit ' + CustomerPatientClientGuest,
  GivenName: 'Given Name',
  FirstName: 'First Name',
  ClinicName: 'Clinic Name',
  LocalAPI: 'Local API',
  Online: 'Online',

  PreferredName: 'Preferred Name / Nickname',
  Address: 'Address',
  AddressLine2: 'AddressLine2',
  State: 'State',
  Suburb: 'Suburb',
  StreetAddress: 'Street Address',
  HomePhone: 'Home Phone',
  Gender: 'Gender',
  Sex: 'Sex',
  Medicare: 'Medicare',
  Search: 'Search',
  Filters: 'Filters',
  Tag: 'Tag',
  Tenants: 'Tenants',
  Tags: 'Tags',
  Suppliers: 'Suppliers',
  Supplier: 'Supplier',
  ContactName: 'Contact Name',
  ContactEmail: 'Contact Email',
  ContactPhone: 'Contact Phone',
  ContactWebsite: 'Contact Website',
  ShowInactive: 'Show Inactive',
  AvailableLocations: 'Available Locations',
  TotalInstances: 'Total Instances',
  Permissions: 'Permissions',
  PermissionUpdated: 'Permission Updated',
  UserType: 'User Type',
  PrescriberNumber: 'Prescriber Number',
  ProviderNumber: 'Provider Number',
  PrescribeLocation: 'Prescribe Location',
  Create: 'Create',
  Update: 'Update',
  Back: 'Back',
  Name: 'Name',
  DisplayName: 'Display Name',
  Surname: 'Surname',
  LastName: 'Last Name',
  CanLoginOnline: 'Can login online',
  PreferredLocations: 'Preferred Locations',
  PreferredUsers: 'Preferred Users',
  ProfileImage: 'Profile Image',
  HealthCards: 'Health Cards',
  CardNumber: 'Card Number',
  Number: 'Number',
  Type: 'Type',
  NoCardsMessage: 'No health care/medicare cards have been added',
  Other: 'Other',
  CanEmail: 'Can Email',
  CanSMS: 'Can SMS',
  Send: 'Send',
  Email: 'Email',
  Business: 'Business',
  Users: 'Users',
  User: 'User',
  Invite: 'Invite',
  InvitedUser: 'Invited Users',
  Created: 'Created',
  CreatedBy: 'Created By',
  Updated: 'Updates',
  Expires: 'Expires',
  Actions: 'Actions',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  CancelInvite: 'Cancel Invite',
  Barcode: 'Barcode',
  BarcodedProduct: 'Barcoded Product',
  BarcodedProductExplainer1: 'Typically used for injectables (such as Botox or Dysport).',
  BarcodedProductExplainer2:
    'Allows for items to have multiple uses before being removed from stock.',
  SharedBarcode: 'Shared Barcode',
  SharedBarcodeExplainer: 'Typically used for retail stock and lower value consumables',
  Price: 'Price',
  PriceEx: 'Price Ex',
  TotalPrice: 'Total Price',
  ProductBarcodeType: 'Product Barcode Type',
  BarcodeNumberUse: 'Number of use',
  RetailPrice: 'Retail Price',
  CostPrice: 'Cost Price',
  AddTaxRate: 'Add Tax Rate',
  TaxPercentage: 'Tax Percentage',
  ProductDetail: 'Product Detail',
  ProductUses: 'Product Uses',
  ProductType: 'Product Type',
  SellInClinic: 'Sell In-Clinic',
  SellOnline: 'Sell Online',
  TrackStock: 'Track Stock',
  ProductIsA: '{{product}} is a',
  Bottle: 'Bottle',
  Jar: 'Jar',
  Vial: 'Vial',
  Syringe: 'Syringe',
  Card: 'Card',
  Box: 'Box',
  Unit: 'Unit',
  Millilitres: 'Millilitres',
  Grams: 'Grams',
  Cycles: 'Cycles',
  Pulses: 'Pulses',
  Uses: 'Uses',

  ChooseTag: 'Choose Tag',
  ChangeTag: 'Change Tag',
  ChooseSupplier: 'Choose Supplier',
  InternalDescription: 'Internal Description',
  PublicDescription: 'Public Description',
  UnitQuantity: 'Unit Quantity',
  ContainingUnits: 'Containing',
  SuppliersAndCosts: 'Suppliers And Costs',
  Currency: 'Currency',
  RetailPrices: 'Retail Prices',
  Edit: 'Edit',
  Use: 'Use',
  Remove: 'Remove',
  EditUser: 'Edit User',
  Error: 'Error',
  start: 'Start',
  OfflineBanner: 'Offline - You will not be able to perform certain actions',
  ClickToReadTOS: 'Read Terms Of Service & Privacy Policy',
  BreakDuration: 'Break Duration',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  Approve: 'Approve',
  UnApprove: 'Un Approve',
  LoadMore: 'Load More',
  Description: 'Description',
  Completed: 'Completed',
  Active: 'Active',
  Cancelled: 'Cancelled',
  Success: 'Success',
  MoneyInput: 'Money Input',
  CustomFields: 'Custom Fields',
  CustomImage: 'Custom Image',
  CustomResource: 'Custom Resource',
  Grouping: 'Grouping',
  DataType: 'Data Type',
  Choices: 'Choices',
  Select: 'Select',
  SelectUser: 'Select User',
  UpdateDefaultPrice: 'Update Default Price',
  NoFiltersSelected: 'Select at least one filter to see results',
  CreateCustomer: 'Create ' + CustomerPatientClientGuest,
  CreateCustomerNoDuplicates: `No potentially duplicated ${CustomerPatientClientGuest} for provided details.`,
  CreateCustomerHasDuplicates: `Existing ${CustomerPatientClientGuest} matches for provided details. Review to avoid potential duplicates.`,
  CreateCustomerInvalid: 'First name and last name are required to create',
  OpenInstead: 'Open instead',
  CustomerBrand: CustomerPatientClientGuest + ' Brand',
  CustomerBrandsExplainer:
    'Default Brand for communications for this ' + CustomerPatientClientGuest,
  PhoneInputPlaceholder: 'Enter phone number',

  Invoice: 'Invoice',
  InvoiceItems: 'Invoice Items',
  RecentInvoice: 'Recent Invoice',
  CreateInvoice: 'Create Invoice',
  AppliedToInvoice: 'Applied to invoice',
  AppliedToInvoiceDeleted: 'Deleted invoice application',
  DeleteInvoice: 'Delete Invoice',
  DeletedInvoice: 'Deleted Invoice',
  DeleteInvoiceConfirm: 'Confirm deletion',
  DeleteInvoicePayments:
    'This invoice has associated payments, you can choose to convert them to credit or also delete them',
  DeleteInvoiceKeptCredit: 'Will be kept as credit',
  Add: 'Add',
  AddAnother: 'Add Another',
  NoSearchResults: 'No items match your search request',
  FindOrScan: 'Find an item or scan a barcode',
  DefaultInvoiceUser: 'Items from search will be added here',
  NoItems: 'No Items',
  NoCartItems: 'Newly added items will go here',
  EditCartItem: 'Edit Cart Item',
  Subtotal: 'Subtotal',
  Owing: 'Owing',
  InvoiceIsOwing: 'This invoice has an amount owing',
  InvoiceSearchGetStarted: 'To get started, search for an item or pick from recommendations',
  Recommendation: 'Recommendation',
  Transcribe: 'Transcribe',
  LiveTranscript: 'Live Transcript',
  GenerateSummary: 'Generated Summary',
  Stop: 'Stop',
  Invalid: 'Invalid',
  Validated: 'Validated',
  Validating: 'Validating',
  Via: 'via',
  NoPrice: 'No price available',
  PriceAdjusted: '(adjusted)',
  PriceChanged: 'Price Changed',
  PriceIncreased: 'Increased from the standard price',
  PriceDecreased: 'Decreased from the standard price',
  DefaultPrice: 'Default Price',
  SetDefaultPrice: 'Reset Price',
  Payment: 'Payment',
  PaymentMethod: 'Payment Method',
  PaymentUses: 'Use History',
  PaymentWithCredit: 'Payment with Credit',
  KeepAsCredit: 'Keep as Credit',
  PaymentDeleted: 'Payment Deleted',
  DeletedPayment: 'Deleted Payment',
  Credit: 'Credit',
  QTYofCredit: '{{credit}} credit',
  QTYofCreditExplainer: 'From {{creditDate}}',
  AddPayment: 'Add Payment',
  PaymentAmount: 'Payment Amount',
  NewPayment: 'Add New Payment',
  EditPayment: 'Modify Payment',
  PaymentMultipleInvoices: 'This payment has been used on multiple invoices',
  OverPaymentCredit: 'Over payment will be added as Credit',
  Full: 'Full',
  UnitPrice: 'Unit Price',
  ChangeUser: 'Change User',
  UserCannotSell: 'Cannot be sold by user',
  AvailableOn: 'Available On',
  ShowAll: 'Show All',
  Status: 'Status',
  NoBillingHistory: `This ${CustomerPatientClientGuest} has no Billing History yet`,

  Pathology: 'Pathology',
  PathologyRequest: 'Pathology Request',
  PathologyResult: 'Pathology Result',
  UnlinkedResults: 'Unlinked Results',
  ViewResult: 'View Result',
  ViewResultNumber: 'View Result {{number}}',
  IncompleteRequests: 'Incomplete Requests',
  RequestPathology: 'Request Pathology',
  PathologyRequestCompleted: 'Pathology request completed',
  ShowCompletedRequests: 'Show Completed Requests',
  HideCompletedRequests: 'Hide Completed Requests',
  Request: 'Request',
  NoResultsToProcess: 'No Results to Process',
  CreatePathologyRequest: 'Create Pathology Request',
  Received: 'Received',
  RequestDoctor: 'Request Doctor',
  DateRequested: 'Date Requested',
  Test: 'Test',
  SelectedTests: 'Selected Tests',
  SelectTests: 'Select Tests',
  AddTest: 'Add Test',
  AddGroupingTests: 'Add {{grouping}} Tests',
  AddCustomTest: 'Add Custom Test',
  TestName: 'Test name',
  PendingTests: 'Pending Tests',
  Comments: 'Comments',
  ShownOnRequestForm: 'Shown on request form',
  InProgress: 'In Progress',
  Released: 'Released',
  NotReleased: 'Not Released',
  ReleasedToCustomer: 'Released to ' + CustomerPatientClientGuest,
  ReleaseResultToCustomerPortal: `Release result to ${CustomerPatientClientGuest} portal`,
  ReleaseToCustomer: 'Release To ' + CustomerPatientClientGuest,
  UnReleaseFromCustomer: 'Un-Release from ' + CustomerPatientClientGuest,
  Abnormal: 'Abnormal',
  AbnormalCount: '{{qty}} Abnormal',
  AbnormalRequestNotNotified: `Abnormal result (${CustomerPatientClientGuest} NOT notified)`,
  Pending: 'Pending',
  PendingCount: 'Pending {{completed}}/{{total}}',
  CloseRequest: 'Close Request',
  CloseRequestConfirm: 'Confirm you want to close this Request: ',
  AlsoClosingRequest: 'You are also closing the Request',
  ReopenRequest: 'Reopen Request',
  ResultStatus: 'Result - Status',
  ResultDetails: 'Result - Details',
  ResultReport: 'Result - Report',
  Reviewed: 'Reviewed',
  ToReview: 'To Review',
  Unlinked: 'Unlinked',
  LinkToCustomer: 'Link to ' + CustomerPatientClientGuest,
  LinkToRequest: 'Link to Request',
  LinkResult: 'Link Result',
  FindCustomerForResult: `Find ${CustomerPatientClientGuest} for this Result`,
  NotifyCustomerOfResult: `Notify ${CustomerPatientClientGuest} of Result`,
  SelectRequestForThisResult: `Select the Request that matches this Test Result`,
  SelectTestForResult: 'Select the test(s) this Result relates to',
  SaveAndLink: 'Save and Link',
  ConfirmSaveAndLink: `Confirm saving these Results and linking to ${CustomerPatientClientGuest} Request`,
  ReminderDueIn: 'Reminder Due In X Days',
  DueXDaysAgo: 'Due {{days}} days ago',
  ViewPDF: 'View PDF',
  UploadResultPDF: 'Upload Result PDF',
  OpenInBrowser: 'Open in Browser',
  DeliveryVia: 'Delivery Via',
  Lab: 'Lab',

  MarkAsUnread: 'Mark as Unread',
  MarkAsRead: 'Mark as Read',
  Unread: 'Unread',
  ViewByRequest: 'By Request',
  ViewByTest: 'By Test',
  NoPathology: `This ${CustomerPatientClientGuest} has never had Pathology Requested`,

  Prescriptions: 'Prescriptions',
  NewPrescription: 'New Prescription',
  NoPrescriptions: 'No prescriptions',
  CreatePrescription: 'Create Prescriptions',
  Instructions: 'Instructions',
  QTY: 'QTY',
  Repeats: 'Repeats',
  RepeatIntervalDays: 'Repeat Interval (days)',
  RepeatsAvailable: 'Repeat Available',
  Regulation48: 'Regulation 48',
  DispenseAllAtOnce: 'Dispense all at once',
  PBSScript: 'PBS Script',
  PrivateScript: 'Private Script',
  ScheduleNumber: 'Schedule {{number}}',
  eRXToken: 'eRX Token',
  eRXStatus: 'eRX {{status}}',
  SendTo: 'Send To',
  PharmacyPartner: 'Pharmacy Partner',
  NotesForPharmacy: 'Notes For Pharmacy',
  ReasonForPrescribing: 'Reason For Prescribing (TGA SAS/AP Indications)',
  PINNumber: 'PIN Number',
  PrescriptionRequiresPIN: 'Prescribing this medication requires we verify your login PIN',
  RequiredForS8s: 'Required for prescribing S8s',
  PrescribedBefore: 'Prescribed Before',
  MedicationName: 'Medication Name',
  AddCurrencyPrice: 'Add {{currency}} price',
  LastDispenseDateExplainer: 'An empty value here reflect a script that has never been dispensed',
  LastDispenseDate: 'Last Dispense Date',

  NoMessagesOfStatus: 'No {{status}} messages',
  Portal: 'Portal',
  SMS: 'SMS',
  QuickReplies: 'Quick Replies',
  SendBookingLink: 'Send Booking Link',
  ReOpen: 'Re-open',
  EditMessage: 'Edit Message',
  NewMessage: 'New Message',
  EditPortalMessage: 'Edit Portal Message',
  MarkCompleted: 'Mark Completed',
  TypeYourMessageTypeHere: 'Type your {{channel}} message here',
  NoChannelsAvailable: 'There are no reply methods available',
  SelectAChannel: 'Select reply method',
  MarkCompletedAlert:
    'Marking as completed will remove this customer from the Inbox. It will be reopened if any new messages are sent.',
  Open: 'Open',

  SelectedCount: 'Selected ({{count}})',
  UnselectedCount: 'Not Selected ({{count}})',

  SignupCode: 'Signup Code',
  SignupExplainer: 'Discover the leading CRM for competitive and compliant clinics',
  Resource: 'Resource',
  TOS: 'I agree to the Autumn Terms Of Service & Privacy Policy',
  ResourceType: 'Resource Type',
  ResourceCreated: 'Resource Created',
  ResourceDetail: 'Resource Detail',
  Instances: 'Instances',
  AddInstance: 'Add Instance',
  InviteCancelText: 'Invitation cancelled',
  TenantCreated: 'Tenant created successfully',
  TenantUserInvitedText: 'Tenant user invited successfully',
  DeleteUserInviteText: 'Are you sure you want to cancel the invite?',
  TimesheetEntryAdded: 'Timesheet Entry added',
  ApproveTimesheetText: 'Are you sure you want to approve time entry?',
  UnApproveTimesheetText: 'Are you sure you want to un approve time entry?',
  AddPriceException: 'Add Price Exception',
  AddDefaultPrice: 'Add Default Price',
  AddCostPrice: 'Add Cost Price',
  locationUserWarning: 'Either User or Location is required',
  SignupCodeExplainer: 'Provided by your Autumn representative',
  MakeActive: 'Make Active',
  MakeInActive: 'Make Inactive',
  Timezone: 'Timezone',
  ABN: 'ABN',
  ContactDetail: 'Contact Details',
  Phone: 'Phone',
  Fax: 'Fax',
  Street: 'Street',
  Postcode: 'Postcode',
  Country: 'Country',
  EditLocation: 'Edit Location',
  ImageAngles: 'Image Angles',
  ChooseAngle: 'Choose Angle',
  ChangeAngle: 'Change Angle',
  Instruction: 'Instruction',
  ExampleImage: 'Example Image',
  ImageAnglesExplainer: 'Will be used at treatment',
  PublicName: 'Public Name',
  PublicNameExplainer: 'Name shown to Customers online - leave blank to use customer',
  PublicTagExplainer: 'Tag shown to Customers online - leave blank to use tag',
  PublicTag: 'Public Tag',
  PublicImage: 'Public Image',
  PublicDescriptionExplainer: 'Description of treatment shown to Customers online',
  TreatmentEdit: 'Edit Treatment',
  TreatmentOptions: 'Treatment Options',
  TreatmentDefaults: 'Treatment Defaults',
  Aftercare: 'Aftercare',
  CustomAftercare: 'Custom Aftercare',
  AddTreatmentOption: 'Add Treatment Option',
  Prices: 'Prices',
  Timing: 'Timing',
  Slots: 'Slots',
  AddTimingException: 'Add Timing Exception',
  DefaultTiming: 'Default Timing',
  AddDefaultTiming: 'Add Default Timing',
  AddImageRequirement: 'Add Image Requirement',
  ImageRequirements: 'Image Requirements',
  FrequencyCapture: 'Capture every X {{type}}',
  FrequencyTypeCapture: '{{frequency}} since last capture',
  FrequencyType: 'Frequency Type',
  Capture: 'Capture',
  ImageCaptureType: 'Image Capture Type',
  Angles: 'Angles',
  Angle: 'Angle',
  Frequency: 'Frequency',
  ActiveCount: 'Active Count',
  ResourceRequirement: 'Resource Requirement',
  AddResourceRequirement: 'Add Resource Requirement',
  Resources: 'Resources',
  RequiredResources: 'Required Resources',
  CaptureInterval: 'Capture Interval',
  Optional: 'Optional',
  EditResourceNote:
    'Any of the selected resources must be available to book this treatment. Add a new resource requirement if you need more multiple resources be available to book.',
  ProductOrderTxt: 'Where this product is ordered from',
  SellProductToCustomer: 'How much we sell this product to Customers for',
  BarcodeValid: 'Barcode is valid',
  BarcodeInvalid: 'Barcode is Invalid',
  Domain: 'Domain',
  Brand: 'Brand',
  Fonts: 'Fonts',
  Logo: 'Logo',
  LogoSquare: 'Logo Square',
  EditBrand: 'Edit Brand',
  CheckRecords: 'Check Records',
  AddDomain: 'Add Domain',
  Value: 'Value',
  NewLocation: 'New Location',
  Afterpay: 'Afterpay',
  AfterpayId: 'Afterpay Id',
  PaymentGateway: 'Payment Gateway',
  PaymentGatewayID: 'payment Gateway ID',
  PaymentGatewayAPIPublicKey: 'Payment Gateway API Public Key',
  PortalEnabled: 'Portal Enabled',
  PortalPassword: 'Portal Password',
  PortalMessage: 'Portal Message',
  PortalFeatures: 'Portal Features',
  BrandAlreadyExist: 'Brand id already exists - choose a different brand ID',
  PrimaryColor: 'Primary Color',
  SecondaryColor: 'Secondary Color',
  TreatmentOptionExplainer:
    'Create a treatment option for each unique variety of this treatment that customers can be booked, sold or performed',
  ImageRequirementNeedExplainer: 'Image requirement needs to be save:',
  ImageRequirementRemoveExplainer: 'Image requirement will be deleted:',
  PriceNeedExplainer: 'Prices needs to be save for {{currency}}:',
  TimingNeedExplainer: 'Timings needs to be save:',
  TreatmentOptionNeedExplainer: 'Treatment Options needs to be save:',
  ResourceNeedExplainer: 'Resource requirements needs to be save:',
  ResourceRemoveExplainer: 'Resource requirement will be deleted:',
};
