import React from 'react';
import { useRecoilState } from 'recoil';
import { devState } from '../state/devState';
import { AutumnHStack, AutumnPressable, AutumnText } from '../../../Common/components/ui';
import { useIsDev } from '../../../Common/hooks/useIsDev';

export function DevSwitcher() {
  const [{ localAPI }, setState] = useRecoilState(devState);

  const onSetLocalAPI = (value: boolean) => setState({ localAPI: value });

  const isDev = useIsDev();

  if (!isDev) return null;

  return (
    <AutumnHStack
      borderRadius={5}
      bg={'black'}
      alignItems={'center'}
      position={'absolute'}
      right={5}
      p={2}
      bottom={5}
    >
      <AutumnPressable onPress={() => onSetLocalAPI(!localAPI)}>
        <AutumnText fontSize={10} color={'white'}>
          {localAPI ? 'Local API - Toggle' : 'Hosted API - Toggle'}
        </AutumnText>
      </AutumnPressable>
    </AutumnHStack>
  );
}
