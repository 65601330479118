import React from 'react';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Calendar, CalendarTheme, toDateId } from '@marceloterreiro/flash-calendar';

import theme from './theme';
import { AutumnPressable } from './AutumnPressable';
import { AutumnPopover } from './AutumnPopover';
import { faCalendarAlt, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { AutumnButton } from './AutumnButton';
import { AutumnScrollView } from './AutumnScrollView';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

dayjs.extend(customParseFormat);

const getDateValue = (value: string) => {
  if (dayjs(value).isValid()) {
    return dayjs(value).toDate();
  }
  return dayjs().toDate();
};

export function AutumnDatePicker({
  value,
  endValue,
  formatValue = 'YYYY-MM-DD',
  formatDisplay = 'DD MMM YYYY',
  onChange,
  closeOnSelection = true,
  startWithYear = false,
  disabled = false,
  ...rest
}: {
  value: string;
  formatValue?: string;
  formatDisplay?: string;
  endValue?: string;
  disabled?: boolean;
  closeOnSelection?: boolean;
  startWithYear?: boolean;
  onChange: (value: string) => void;
} & AutumnFieldWrapperProps) {
  const [show, setShow] = React.useState(false);
  const [showMonthPicker, setShowMonthPicker] = React.useState(false);
  const [showYearPicker, setShowYearPicker] = React.useState(startWithYear);
  const [calendarDate, setCalendarDate] = React.useState<Date>(getDateValue(value));

  const onChangeDate = (dateValue: string) => {
    const newDate = dayjs(dateValue, formatValue);
    onChange(newDate.format(formatValue));

    if (closeOnSelection) {
      setShow(false);
    }
  };

  const changeMonth = (changeBy: number) => {
    setCalendarDate(dayjs(calendarDate).add(changeBy, 'month').toDate());
    setShowMonthPicker(false);
  };

  const changeYear = (changeBy: number) => {
    setCalendarDate(dayjs(calendarDate).add(changeBy, 'year').toDate());
    setShowYearPicker(false);
    setShowMonthPicker(startWithYear);
  };

  const setToToday = () => {
    onChange(dayjs().format(formatValue));
    if (closeOnSelection) {
      setShow(false);
    }
  };

  const getDateValueStr = (value: string, format: string = formatDisplay) => {
    if (value) {
      return dayjs(value).format(format);
    }
    return 'Not set';
  };

  const onPress = () => {
    if (!disabled) {
      setShow(true);
    }
  };

  return (
    <AutumnPopover
      popoverWidth={400}
      show={show}
      onOutsidePress={() => setShow(false)}
      popoverComponent={
        <AutumnVStack padding={'s'} width={400}>
          <AutumnHStack justifyContent={'space-between'} alignItems={'center'}>
            <AutumnButton
              variant={'link'}
              leftIcon={faChevronLeft}
              onPress={() => setCalendarDate(dayjs(calendarDate).add(-1, 'month').toDate())}
            />
            <AutumnHStack>
              <AutumnButton
                variant={'link'}
                localeKey={{ key: getDateValueStr(calendarDate.toString(), 'MMMM') }}
                onPress={() => setShowMonthPicker(!showMonthPicker)}
              />
              <AutumnButton
                variant={'link'}
                localeKey={{ key: getDateValueStr(calendarDate.toString(), 'YYYY') }}
                onPress={() => setShowYearPicker(!showYearPicker)}
              />
            </AutumnHStack>
            <AutumnButton
              variant={'link'}
              leftIcon={faChevronRight}
              onPress={() => setCalendarDate(dayjs(calendarDate).add(1, 'month').toDate())}
            />
          </AutumnHStack>
          {/*getMonth() is zero index, hence the weird +1*/}
          {showMonthPicker && (
            <MonthPicker currentMonth={calendarDate.getMonth() + 1} changeMonth={changeMonth} />
          )}
          {showYearPicker && (
            <YearPicker currentYear={calendarDate.getFullYear()} changeYear={changeYear} />
          )}
          {!showYearPicker && !showMonthPicker && (
            <Calendar
              onCalendarDayPress={(e) => onChangeDate(e)}
              calendarMonthId={toDateId(calendarDate)}
              calendarActiveDateRanges={[
                {
                  startId: toDateId(getDateValue(value)),
                  endId: toDateId(getDateValue(endValue ?? value)),
                },
              ]}
              theme={calendarTheme}
            />
          )}
          {(!value || value.length === 0) && (
            <AutumnButton localeKey={'SetToToday'} variant={'link'} onPress={setToToday} />
          )}
        </AutumnVStack>
      }
    >
      <AutumnPressable disabled={show || disabled} onPress={onPress}>
        <AutumnFieldWrapper hasFocus={show} {...rest}>
          <AutumnHStack space={2} alignItems={'center'} height={30}>
            <AutumnButton
              padding={0}
              disabled={disabled}
              onPress={onPress}
              leftIcon={faCalendarAlt}
              variant={'inLineLink'}
              localeKey={{
                key: endValue ? 'DatePickerRange' : 'DatePickerSingleDate',
                renderParameters: {
                  startDate: getDateValueStr(value),
                  endDate: getDateValueStr(endValue ?? value),
                },
              }}
            />
          </AutumnHStack>
        </AutumnFieldWrapper>
      </AutumnPressable>
    </AutumnPopover>
  );
}

function MonthPicker({
  currentMonth,
  changeMonth,
}: {
  currentMonth: number;
  changeMonth: (changeMonthOffset: number) => void;
}) {
  const pressedMonth = (month: number) => {
    changeMonth(month - currentMonth);
  };

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <AutumnVStack>
      <AutumnHStack space={'m'} flexWrap={'wrap'} justifyContent={'center'}>
        {months.map((month) => (
          <AutumnButton
            key={month}
            width={100}
            variant={month === currentMonth ? 'primary' : 'link'}
            onPress={() => pressedMonth(month)}
            localeKey={{ key: dayjs(`2000-${month}-1`).format('MMMM') }}
          />
        ))}
      </AutumnHStack>
    </AutumnVStack>
  );
}

function YearPicker({
  currentYear,
  changeYear,
}: {
  currentYear: number;
  changeYear: (changeYearOffset: number) => void;
}) {
  const pressedYear = (year: number) => {
    changeYear(year - currentYear);
  };

  const years: number[] = [];

  for (let i = 1900; i < 2030; i++) {
    years.push(i);
  }

  return (
    <AutumnVStack height={300}>
      <AutumnScrollView>
        <AutumnHStack space={'m'} flexWrap={'wrap'} justifyContent={'center'}>
          {years.map((year) => (
            <AutumnButton
              key={year}
              width={60}
              variant={year === currentYear ? 'primary' : 'link'}
              onPress={() => pressedYear(year)}
              localeKey={{ key: year.toString() }}
            />
          ))}
        </AutumnHStack>
      </AutumnScrollView>
    </AutumnVStack>
  );
}
const calendarAccent = theme.colors.buttonPrimary;
const calendarTheme: CalendarTheme = {
  rowMonth: {
    container: {
      display: 'none',
    },
  },
  rowWeek: {
    container: {
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(52, 52, 52, 0.4)',
      borderStyle: 'solid',
    },
  },
  itemWeekName: { content: { color: 'rgba(52, 52, 52, 0.8)' } },
  itemDayContainer: {
    activeDayFiller: {
      backgroundColor: calendarAccent,
    },
  },
  itemDay: {
    idle: ({ isPressed, isWeekend }) => ({
      container: {
        backgroundColor: isPressed ? calendarAccent : 'transparent',
        borderRadius: 100,
      },
      content: {
        color: isPressed ? 'white' : isWeekend ? 'rgba(52, 52, 52, 0.8)' : '#000000',
      },
    }),
    today: ({ isPressed }) => ({
      container: {
        borderColor: 'rgba(52, 52, 52, 0.5)',
        borderRadius: isPressed ? 100 : 30,
        backgroundColor: isPressed ? calendarAccent : 'transparent',
      },
      content: {
        color: isPressed ? 'white' : calendarAccent,
      },
    }),
    active: ({ isEndOfRange, isStartOfRange }) => ({
      container: {
        backgroundColor: calendarAccent,
        borderTopLeftRadius: isStartOfRange ? 100 : 0,
        borderBottomLeftRadius: isStartOfRange ? 100 : 0,
        borderTopRightRadius: isEndOfRange ? 100 : 0,
        borderBottomRightRadius: isEndOfRange ? 100 : 0,
      },
      content: {
        color: 'white',
      },
    }),
  },
};
