import React, { ReactNode } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnLocaleText } from './AutumnText';
import { AutumnVStack } from './AutumnVStack';
import { Pressable, TextInput, TextInputProps } from 'react-native';
import {
  backgroundColor,
  BackgroundColorProps,
  border,
  BorderProps,
  BoxProps,
  color,
  ColorProps,
  composeRestyleFunctions,
  createRestyleComponent,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
  TextProps,
  typography,
  TypographyProps,
  useRestyle,
} from '@shopify/restyle';
import { Theme } from './theme';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import { bool } from 'yup';

export type AutumnInputElementProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  BoxProps<Theme> &
  LayoutProps<Theme> &
  ColorProps<Theme> &
  BorderProps<Theme> &
  TypographyProps<Theme> &
  TextInputProps;

export const AutumnInputElement = createRestyleComponent<AutumnInputElementProps, Theme>(
  [spacing, backgroundColor, border, color, typography, layout],
  TextInput,
);

export type AutumnFieldWrapperRestyleProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  BorderProps<Theme> &
  TextProps<Theme>;

export type AutumnFieldWrapperProps = {
  label?: keyof typeof enLocalKeys | TranslationProps;
  explainer?: keyof typeof enLocalKeys | TranslationProps;
  isInvalid?: boolean;
  hasFocus?: boolean;
  children?: ReactNode;
} & AutumnFieldWrapperRestyleProps;

const autumnFieldRestyleFunctions = composeRestyleFunctions<Theme, AutumnFieldWrapperRestyleProps>([
  spacing,
  typography,
  backgroundColor,
]);

export function AutumnFieldWrapper({
  label,
  explainer,
  children,
  onLabelPressed,
  isInvalid = false,
  hasFocus = false,
  ...rest
}: AutumnFieldWrapperProps & { onLabelPressed?: () => void }) {
  const props = useRestyle(autumnFieldRestyleFunctions, rest);

  return (
    <AutumnVStack
      backgroundColor={'white'}
      borderColor={isInvalid ? 'red' : hasFocus ? 'black' : 'gray'}
      borderWidth={1}
      space={2}
      padding={2}
      borderRadius={5}
      {...props}
    >
      {label && (
        <Pressable
          disabled={!onLabelPressed}
          onPress={onLabelPressed ? () => onLabelPressed() : undefined}
        >
          <AutumnLocaleText localeKey={label} fontWeight={'bold'} />
          {explainer && <AutumnLocaleText localeKey={explainer} opacity={0.5} />}
        </Pressable>
      )}

      {children}
    </AutumnVStack>
  );
}
