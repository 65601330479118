import { useRecoilState } from 'recoil';
import { currentUserStateAtom } from '../state/authState';
import { fetchDiscoveryAsync, RefreshTokenRequestConfig, TokenResponse } from 'expo-auth-session';
import { issuer, useAuthInfo } from './useAuthInfo';
import { Mutex } from 'async-mutex';
import { Current_User_State } from '../types/types';
import { saveUserToken } from '../utils/tokens';
import { useCallback } from 'react';

const tokenRenewalMutex = new Mutex();

export const useAuthState = () => {
  const [user, setUser] = useRecoilState(currentUserStateAtom);

  const { clientId } = useAuthInfo();

  const isAuthenticated = !!user?.token?.accessToken;

  const getAccessToken = useCallback(async () => {
    if (!user?.token?.accessToken) {
      return;
    }

    if (tokenRenewalMutex.isLocked()) {
      await tokenRenewalMutex.waitForUnlock();
    }

    const tokenResponse = new TokenResponse(user?.token);

    const needsRefresh = tokenResponse.shouldRefresh();

    if (needsRefresh) {
      try {
        await tokenRenewalMutex.acquire();

        const discovery = await fetchDiscoveryAsync(issuer);

        const refreshConfig: RefreshTokenRequestConfig = {
          clientId,
          refreshToken: tokenResponse.refreshToken,
        };

        const refreshedTokenResponse = await tokenResponse.refreshAsync(refreshConfig, discovery);

        await saveUserToken({ ...user, token: refreshedTokenResponse }, user.pin);

        setUser((existingUser: Current_User_State) => {
          return { ...existingUser, token: refreshedTokenResponse };
        });

        return refreshedTokenResponse.accessToken;
      } catch (e) {
        console.error(e);

        setUser((existingUser: Current_User_State) => {
          return { ...existingUser, expired: true };
        });

        return null;
      } finally {
        tokenRenewalMutex.release();
      }
    }

    return tokenResponse.accessToken;
  }, [user]);

  const setUserSessionExpired = () => {
    setUser((existingUser: Current_User_State) => {
      return { ...existingUser, expired: true };
    });
  };

  return {
    isAuthenticated,
    getAccessToken,
    pin: user?.pin,
    user: user?.user,
    setUserSessionExpired,
  };
};
