import { useMutation } from '@apollo/client';
import type { ApolloCache, DefaultContext, OperationVariables } from '@apollo/client/core';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  MutationFunctionOptions,
  MutationHookOptions,
  type MutationTuple,
  NoInfer,
} from '@apollo/client/react/types/types';
import type { FetchResult } from '@apollo/client/link/core';
import { useOfflineOnline } from '../../OnlineOffline/hooks/useOfflineOnline';

export const useAutumnMutation = <
  TData = never,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<never> = ApolloCache<never>,
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: Omit<
    MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
    'notifyOnNetworkStatusChange'
  >,
) => {
  const { online } = useOfflineOnline();

  const [_doMutation, other] = useMutation(mutation, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  const doMutation = (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>,
  ): Promise<FetchResult<TData>> | null => {
    if (!online) return null;

    return _doMutation(options);
  };

  return [doMutation, other] as MutationTuple<TData, TVariables, TContext, TCache>;
};
