import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  AutumnButton,
  AutumnHStack,
  AutumnInput,
  AutumnModal,
  AutumnPopover,
  AutumnPressable,
  AutumnText,
  AutumnVStack,
} from '.';
import {
  backgroundColor,
  BackgroundColorProps,
  border,
  BorderProps,
  BoxProps,
  color,
  ColorProps,
  createRestyleComponent,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
  typography,
  TypographyProps,
} from '@shopify/restyle';
import { Theme } from './theme';
import { TextInputProps } from 'react-native';
import { useTaxRates } from '../../../features/Admin/TaxRates/hooks/useTaxRates';
import { useTaxRatesMutation } from '../../../features/Admin/TaxRates/hooks/useTaxRatesMutation';
import CurrencyInput, { CurrencyInputProps, formatNumber } from 'react-native-currency-input';
import * as currencies from 'iso-country-currency';
import { TaxRate } from '../../../__generatedtypes__/graphql';
import { uuid } from '../../utils/uuid';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

export type AutumnNumberInputElementProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  BoxProps<Theme> &
  LayoutProps<Theme> &
  ColorProps<Theme> &
  BorderProps<Theme> &
  TypographyProps<Theme> &
  CurrencyInputProps &
  Omit<TextInputProps, 'value'>;

export const AutumnNumberInputElement = createRestyleComponent<
  AutumnNumberInputElementProps,
  Theme
>([spacing, backgroundColor, border, color, typography, layout], CurrencyInput);

export type AutumnMoneyInputProps = {
  inputValue: number;
  disabled?: boolean;
  isInvalid?: boolean;
  currencyCode: string;
  onChange: (value: number) => void;
  children?: ReactNode;
} & AutumnFieldWrapperProps &
  Pick<TextInputProps, 'placeholder' | 'autoFocus' | 'keyboardType' | 'secureTextEntry'>;

export type AutumnMoneyWithTaxInputProps = {
  inputValue: AutumnMoneyType;
  disabled?: boolean;
  onChange: (value: AutumnMoneyType, valid: boolean) => void;
} & AutumnFieldWrapperProps &
  Pick<TextInputProps, 'placeholder' | 'autoFocus' | 'keyboardType' | 'secureTextEntry'>;

export type AutumnMoneyType = {
  currencyCode: string;
  taxRateId?: string;
  moneyTotal?: number;
  moneyEx?: number;
  moneyTax?: number;
};

export function FormatMoney(numberToFormat: number, currencyCode: string): string {
  const currencySymbol =
    currencyCode == undefined || currencyCode === ''
      ? '$'
      : currencies.getParamByParam('currency', currencyCode, 'symbol');

  return formatNumber(numberToFormat, {
    precision: 2,
    prefix: currencySymbol,
    delimiter: ',',
    separator: '.',
  });
}

///If input is inclusive, calculates how much of the total is tax
///If input is exclusive, calculate how much tax should be added
export function CalculateTax(
  inputAmount: number,
  inputIsTaxInclusive: boolean,
  taxPercentage: number,
): number {
  let output = 0;

  if (taxPercentage === 0 || inputAmount === 0) return 0;

  if (inputIsTaxInclusive) {
    const taxMultiplier = 1 + taxPercentage / 100;
    output = inputAmount - inputAmount / taxMultiplier;
  } else {
    output = inputAmount * (taxPercentage / 100);
  }

  return output;
}

export function AutumnMoneyInput({
  inputValue,
  onChange,
  currencyCode,
  disabled = false,
  isInvalid = false,
  autoFocus,
  placeholder,
  children,
  ...rest
}: AutumnMoneyInputProps) {
  const currencySymbol =
    currencyCode == undefined || currencyCode === ''
      ? '$'
      : currencies.getParamByParam('currency', currencyCode, 'symbol');

  const inputRef = useRef<HTMLInputElement>();

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <AutumnFieldWrapper {...rest} onLabelPressed={setFocus}>
      <AutumnHStack flex={1} space={2} alignItems={'center'} paddingHorizontal={1} width={'100%'}>
        <AutumnNumberInputElement
          ref={inputRef}
          value={inputValue}
          placeholder={placeholder}
          placeholderTextColor={'gray'}
          flex={1}
          height={30}
          color={isInvalid ? 'red' : 'black'}
          autoFocus={autoFocus}
          editable={!disabled}
          onChangeValue={(value) => onChange(value ?? 0)}
          prefix={currencySymbol}
          delimiter=","
          separator="."
          precision={2}
        />
        {children}
      </AutumnHStack>
    </AutumnFieldWrapper>
  );
}

export function AutumnMoneyWithTaxRateInput({
  inputValue,
  onChange,
  disabled = false,
  autoFocus,
  placeholder,
  ...rest
}: AutumnMoneyWithTaxInputProps) {
  const { taxRateData, taxRatesLoading } = useTaxRates();
  const { upsertTaxRate, updateTaxRateLoading } = useTaxRatesMutation();

  const [taxRate, setTaxRate] = useState<TaxRate | null>(null);
  const [total, setTotal] = useState(inputValue.moneyTotal ?? 0);
  const [ex, setEx] = useState(inputValue.moneyEx ?? 0);
  const [showTaxRates, setShowTaxRates] = useState(false);
  const [newTaxRate, setNewTaxRate] = useState<TaxRate | null>(null);

  useEffect(() => {
    if (taxRatesLoading) {
      return;
    }

    if (taxRateData.length === 0) {
      onChange(inputValue, false);
    } else if (inputValue.taxRateId == null) {
      //default to the highest tax rate percentage
      const taxRates = [...taxRateData] as TaxRate[];
      const sortedTaxRates = taxRates
        .filter((taxRate) => taxRate && taxRate.currencyCode === inputValue.currencyCode)
        .sort((a: TaxRate, b: TaxRate) => b?.taxPercentage - a?.taxPercentage);

      if (sortedTaxRates.length > 0 && sortedTaxRates[0]) {
        calculateChange(total, true, sortedTaxRates[0]);
      }
    } else {
      console.log(taxRateData);
      calculateChange(
        total,
        true,
        taxRateData.find((rate: TaxRate) => rate.id == inputValue.taxRateId) as TaxRate,
      );
    }
  }, [taxRatesLoading, inputValue.currencyCode]);

  const currencySymbol =
    inputValue.currencyCode == undefined || inputValue.currencyCode === ''
      ? '$'
      : currencies.getParamByParam('currency', inputValue.currencyCode, 'symbol');

  const createTaxRate = async (rate: TaxRate) => {
    await upsertTaxRate(newTaxRate?.id, rate);

    calculateChange(total, true, newTaxRate as TaxRate);
    setShowTaxRates(false);
    setNewTaxRate(null);
  };

  const showAddNewTaxRate = () => {
    setNewTaxRate({
      id: uuid(),
      name: 'GST',
      taxPercentage: 10,
      active: true,
      currencyCode: inputValue.currencyCode,
    });
  };

  const changeTotal = (value: number) => {
    if (taxRate) {
      calculateChange(value, true, taxRate);
    }
  };

  const changeEx = (value: number) => {
    if (taxRate) {
      calculateChange(value, false, taxRate);
    }
  };

  const calculateChange = (inputAmount: number, inputIsTaxInclusive: boolean, rate: TaxRate) => {
    const newMoney = inputValue;
    const taxPercentage = rate?.taxPercentage ?? 0;
    const tax = CalculateTax(inputAmount, inputIsTaxInclusive, taxPercentage);

    if (taxPercentage === 0 || inputAmount === 0) {
      newMoney.moneyTotal = inputAmount;
      newMoney.moneyEx = inputAmount;
    } else if (inputIsTaxInclusive) {
      newMoney.moneyTotal = inputAmount;
      newMoney.moneyEx = inputAmount - tax;
    } else {
      newMoney.moneyTotal = inputAmount + tax;
      newMoney.moneyEx = inputAmount;
    }

    newMoney.moneyTax = tax;
    newMoney.taxRateId = rate?.id;

    setTotal(newMoney.moneyTotal);
    setEx(newMoney.moneyEx);
    setTaxRate(rate);
    setShowTaxRates(false);

    onChange(newMoney, true);
  };

  return (
    <AutumnFieldWrapper {...rest}>
      <AutumnHStack flex={1} space={2} alignItems={'center'} paddingHorizontal={1} width={'100%'}>
        <AutumnNumberInputElement
          value={total}
          placeholder={placeholder}
          placeholderTextColor={'gray'}
          flex={1}
          height={30}
          autoFocus={autoFocus}
          editable={!disabled}
          onChangeValue={changeTotal}
          prefix={'Total: ' + currencySymbol}
          delimiter=","
          separator="."
          precision={2}
        />
        {taxRate?.taxPercentage != null && taxRate?.taxPercentage > 0 && (
          <AutumnNumberInputElement
            value={ex}
            placeholder={placeholder}
            placeholderTextColor={'gray'}
            flex={1}
            height={30}
            autoFocus={autoFocus}
            editable={!disabled}
            onChangeValue={changeEx}
            prefix={'Ex Tax: ' + currencySymbol}
            delimiter=","
            separator="."
            precision={2}
          />
        )}
      </AutumnHStack>

      <AutumnPopover
        show={showTaxRates}
        onOutsidePress={() => setShowTaxRates(false)}
        popoverComponent={
          <AutumnVStack space={2} margin={5}>
            {taxRateData
              .filter(
                (taxRate: TaxRate) =>
                  taxRate && taxRate.currencyCode === inputValue.currencyCode && taxRate.active,
              )
              .map((taxRate: TaxRate) => (
                <AutumnHStack
                  key={taxRate.id}
                  space={5}
                  paddingHorizontal={5}
                  alignItems={'center'}
                >
                  <AutumnPressable
                    style={{ flex: 1 }}
                    onPress={() => calculateChange(total, true, taxRate)}
                  >
                    <AutumnText>
                      {taxRate?.name} ({taxRate.taxPercentage}%)
                    </AutumnText>
                  </AutumnPressable>

                  <AutumnButton
                    variant={'link'}
                    onPress={() => calculateChange(total, true, taxRate)}
                    localeKey={'Use'}
                  />
                </AutumnHStack>
              ))}
            <AutumnButton onPress={showAddNewTaxRate} localeKey="AddTaxRate" />
          </AutumnVStack>
        }
      >
        {taxRateData.length === 0 ? (
          <AutumnHStack alignItems={'center'}>
            <AutumnPressable style={{ flex: 1 }} onPress={showAddNewTaxRate}>
              <AutumnText>No tax rates available - add one</AutumnText>
            </AutumnPressable>
            <AutumnButton variant={'link'} onPress={showAddNewTaxRate} localeKey={'Add'} />
          </AutumnHStack>
        ) : (
          <AutumnHStack alignItems={'center'}>
            <AutumnPressable style={{ flex: 1 }} onPress={() => setShowTaxRates(true)}>
              <AutumnText>
                {taxRate?.name} ({taxRate?.taxPercentage}%)
              </AutumnText>
            </AutumnPressable>
            <AutumnButton
              variant={'link'}
              onPress={() => setShowTaxRates(true)}
              localeKey={'Change'}
            />
          </AutumnHStack>
        )}
      </AutumnPopover>

      <AutumnModal
        show={!!newTaxRate}
        type={'centered'}
        title={'Create'}
        onClose={() => setNewTaxRate(null)}
      >
        <AutumnVStack space={2}>
          <AutumnInput
            label={'Name'}
            value={newTaxRate?.name}
            onChange={(val) =>
              setNewTaxRate((prevState: TaxRate) => {
                return {
                  ...prevState,
                  name: val,
                } as TaxRate;
              })
            }
          />

          <AutumnFieldWrapper {...rest} label={'TaxPercentage'}>
            <AutumnHStack
              flex={1}
              space={2}
              alignItems={'center'}
              paddingHorizontal={1}
              width={'100%'}
            >
              <AutumnNumberInputElement
                value={newTaxRate?.taxPercentage ?? 0}
                placeholder={placeholder}
                placeholderTextColor={'gray'}
                height={30}
                onChangeValue={(val: number) =>
                  setNewTaxRate((prevState: TaxRate) => {
                    return {
                      ...prevState,
                      taxPercentage: val,
                    };
                  })
                }
                prefix={''}
                suffix={'%'}
                delimiter=""
                separator="."
                precision={2}
              />
            </AutumnHStack>
          </AutumnFieldWrapper>
          <AutumnButton
            isLoading={updateTaxRateLoading}
            disabled={newTaxRate?.name.length === 0}
            onPress={() => createTaxRate(newTaxRate as TaxRate)}
            localeKey="Save"
          />
        </AutumnVStack>
      </AutumnModal>
    </AutumnFieldWrapper>
  );
}
