import React, { useEffect } from 'react';
import { View } from 'react-native';

export const AutumnTurnstile = ({ callback }: { callback: (value: string) => void }) => {
  useEffect(() => {
    // @ts-expect-error CF Code
    turnstile.render('#turnstile', {
      sitekey: '0x4AAAAAAAkQEjZxcg6ZWeJO',
      theme: 'light',
      callback: (token: string) => {
        callback(token);
      },
    });
  }, []);

  return <View nativeID={'turnstile'} />;
};
