import React from 'react';
import { AutumnLocaleText } from './AutumnText';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';
import { enLocalKeys } from '../../../../WebApp/features/Localisations/locales/en';

export function AutumnColorPicker({
  value,
  onChange,
  label,
  disabled = false,
}: {
  value: string;
  disabled?: boolean;
  label?: keyof typeof enLocalKeys;
  onChange: (value: string) => void;
}) {
  return (
    <>
      <AutumnVStack space={2} justifyContent={'center'}>
        <AutumnHStack flexDirection={'column'}>
          {label && <AutumnLocaleText localeKey={label} fontWeight={'bold'} />}
          <input
            value={value}
            disabled={disabled}
            type={'color'}
            style={{
              marginTop: '10px',
              padding: '6.5px',
              border: '1px solid rgb(212, 212, 212)',
              boxShadow: 'none',
              fontFamily: 'DM Sans',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderRadius: '4px',
            }}
            onChange={(e) => onChange(e.target.value)}
          />
        </AutumnHStack>
      </AutumnVStack>
    </>
  );
}
