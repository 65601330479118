import type { OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import type { NoInfer, QueryHookOptions } from '@apollo/client/react/types/types';
import { useQuery } from '@apollo/client';
import { useOfflineOnline } from '../../OnlineOffline/hooks/useOfflineOnline';

export const useAutumnQuery = <
  TData = never,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: Omit<
    QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
    'notifyOnNetworkStatusChange'
  >,
) => {
  const { online } = useOfflineOnline();

  const result = useQuery(query, {
    ...options,
    fetchPolicy: online ? options?.fetchPolicy ?? 'cache-and-network' : 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  return result;
};
