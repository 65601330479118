import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { useAuthState } from './WebApp/features/Login/hooks/useAuthState';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { ToastProvider } from './WebApp/Common/providers/ToastProvider';
import { ConfigProvider } from './WebApp/features/Config/providers/ConfigProvider';
import { OnboardProvider } from './WebApp/features/Onboarding/providers/OnboardProvider';
import { ThemeProvider } from '@shopify/restyle';
import theme from './WebApp/Common/components/ui/theme';
import { useFonts } from 'expo-font';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PortalProvider } from '@gorhom/portal';
import { StatusBar } from 'expo-status-bar';
import { maybeCompleteAuthSession } from 'expo-web-browser';
import { GraphQLProvider } from './WebApp/features/GraphQL/provider/GraphQLProvider';
import { View } from 'react-native';
import { AutumnLoaderFullscreen } from './WebApp/Common/components/ui/AutumnLoaderFullscreen';
import CallingProvider from './WebApp/features/Calling/providers/CallingProvider';
import { AutumnLightweightLoader } from './WebApp/Common/components/ui/AutumnLightweightLoader';
import { PathRouter } from './WebApp/Common/components/Navigation';
import { Route, Routes } from 'react-router';
import { DevSwitcher } from './WebApp/features/DevSwitcher/components/DevSwitcher';
import { OfflineOnlineProvider } from './WebApp/features/OnlineOffline/components/OfflineOnlineProvider';
import RealtimeProvider from './WebApp/features/Realtime/provider/RealtimeProvider';

maybeCompleteAuthSession();

const Login = React.lazy(() => import('./WebApp/features/Login/components/Login'));
const InnerApp = React.lazy(() => import('./WebApp/WebApp'));
const SignUp = React.lazy(() => import('./WebApp/features/Signup/views/Signup'));
const LoginRedirect = React.lazy(() => import('./WebApp/features/Login/components/LoginRedirect'));

if (__DEV__) {
  loadDevMessages();
  loadErrorMessages();
}

const AppWrapper = () => {
  const { isAuthenticated } = useAuthState();

  useFonts({
    CormorantInfant: require('./fonts/CormorantInfant-Bold.ttf'),
    Mulish: require('./fonts/Mulish-VariableFont_wght.ttf'),
  });

  if (!isAuthenticated) {
    return (
      <Suspense fallback={<AutumnLoaderFullscreen />}>
        <OfflineOnlineProvider>
          <PortalProvider>
            <PathRouter>
              <Routes>
                <Route element={<Login />} path={'/signin'} />
                <Route element={<SignUp />} path={'/signup'} />
                <Route element={<LoginRedirect />} path="*" />
              </Routes>
            </PathRouter>
          </PortalProvider>

          <DevSwitcher />
        </OfflineOnlineProvider>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<AutumnLightweightLoader />}>
      {/*This prevents portal content (modals) from creating scroll bars*/}
      <View style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
        <SafeAreaProvider>
          <OfflineOnlineProvider>
            <GraphQLProvider>
              <ConfigProvider>
                <ToastProvider>
                  <PortalProvider>
                    <OnboardProvider>
                      <RealtimeProvider>
                        <CallingProvider>
                          <StatusBar style={'light'} />
                          <InnerApp />
                        </CallingProvider>
                      </RealtimeProvider>
                    </OnboardProvider>
                  </PortalProvider>
                </ToastProvider>
              </ConfigProvider>
            </GraphQLProvider>
          </OfflineOnlineProvider>
        </SafeAreaProvider>
      </View>
    </Suspense>
  );
};

const RecoilWrapper = () => {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <AppWrapper />
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default RecoilWrapper;
