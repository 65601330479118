import { AutumnVStack } from '../AutumnVStack';
import React, { useEffect, useState } from 'react';
import { AutumnAlert, AutumnButton, AutumnHStack, AutumnLoader, Button } from '../index';
import { AutumnBox } from '../AutumnBox';
import { Platform } from 'react-native';
import { AnyObject, ObjectSchema, ValidationError } from 'yup';
import { AutumnFormInternalValidation, AutumnFormProps, AutumnFormSection } from './types';
import { AutumnFormSectionElement } from './AutumnFormSection';
import { InternalFormContext } from './context';
import { useOfflineOnline } from '../../../../features/OnlineOffline/hooks/useOfflineOnline';
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons/faWifiSlash';

export function AutumnForm<T>({
  disabled,
  elements,
  sections,
  loading,
  initialValues,
  onValues,
  columns = 1,
  onSubmit,
  error,
  onDelete,
  buttonTitle,
  onValid,
  validation,
}: AutumnFormProps<T>) {
  const validationOnLoad = () => {
    try {
      (validation as ObjectSchema<AnyObject>).validateSync(initialValues, {
        abortEarly: false,
      });

      return {
        map: Object.keys(initialValues ?? {}).reduce(
          (obj: { [key: string]: AutumnFormInternalValidation }, key: string) => {
            obj[key] = { valid: true } as AutumnFormInternalValidation;
            return obj;
          },
          {},
        ),
        status: true,
      };
    } catch (e) {
      const computedValidationMap = Object.keys(initialValues ?? {}).reduce(
        (obj: { [key: string]: AutumnFormInternalValidation }, key: string) => {
          const isInError = (e.inner as ValidationError[])?.find((a) => a.path === key);
          obj[key] = {
            valid: !isInError,
            message: isInError?.message,
          } as AutumnFormInternalValidation;
          return obj;
        },
        {},
      );

      return { map: computedValidationMap, status: false };
    }
  };

  const [width, setWidth] = useState<number | undefined>(undefined); //set on layout
  const [state, setState] = useState<T | null>(initialValues ?? null);

  const { online } = useOfflineOnline();

  const [validationStatus, setValidationStatus] = useState(validationOnLoad().status);
  const [validationMap, setValidationMap] = useState<Record<string, AutumnFormInternalValidation>>(
    validationOnLoad().map,
  );
  console.log('🚀 ~ validationMap:', validationMap);

  const [clickedSubmit, setClickedSubmit] = useState(false);

  const onChange = (key: string, value: string | boolean | string[], valid: boolean = true) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setState((prevState) => {
      const newState = {
        ...prevState,
        [key]: value,
      };

      if (newState && !valid && key in newState) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete newState[key];
      }

      if (validation) {
        try {
          (validation as ObjectSchema<AnyObject>).validateSync(newState, {
            abortEarly: false,
          });

          setValidationMap(
            Object.keys(newState).reduce(
              (obj: { [key: string]: AutumnFormInternalValidation }, key: string) => {
                obj[key] = { valid: true } as AutumnFormInternalValidation;
                return obj;
              },
              {},
            ),
          );

          setValidationStatus(true);

          if (onValues) onValues(newState as T, true);

          if (onValid) onValid(true);
        } catch (e) {
          const computedValidationMap = Object.keys(newState).reduce(
            (obj: { [key: string]: AutumnFormInternalValidation }, key: string) => {
              const isInError = (e.inner as ValidationError[])?.find((a) => a.path === key);
              obj[key] = {
                valid: !isInError,
                message: isInError?.message,
              } as AutumnFormInternalValidation;
              return obj;
            },
            {},
          );

          setValidationMap(computedValidationMap);

          setValidationStatus(false);

          if (onValid) onValid(false);

          if (onValues) onValues(newState as T, false);
        }
      } else {
        if (onValues && newState) {
          onValues(newState as T);
        }
      }

      return newState;
    });
  };

  const pressedOnSubmit = () => {
    if (loading) return;

    setClickedSubmit(true);

    if (onSubmit && state) {
      if (validation) {
        if (validationStatus) {
          onSubmit(state);
        }
      } else {
        onSubmit(state);
      }
    }
  };

  useEffect(() => {
    if (initialValues !== undefined && (state === null || state === undefined)) {
      setState(initialValues);
    }
  }, [loading, initialValues, state]);

  const filteredElements = elements.filter((elem) => {
    if (!elem.hidden) return true;

    if (typeof elem.hidden === 'function') {
      return !elem.hidden(state as T);
    }

    return !elem.hidden;
  });

  const uniqueSections = [...new Set(filteredElements.map((el) => el.section))];

  //there is no section config, but more than 1 column. So pass that column down to the only section
  const passColumnsToSection = (sections == undefined || sections.length === 0) && columns > 1;

  const sectionWidth = passColumnsToSection
    ? width ?? 0
    : (width ?? 0) / columns - (columns - 1) * 16;

  const formSections = uniqueSections.map((sectionName) => {
    const sectionConfig: AutumnFormSection =
      sections?.find((s) => s?.name === sectionName) ??
      ({ name: sectionName, columns: passColumnsToSection ? columns : 1 } as AutumnFormSection);

    if (sectionConfig.parentSection) {
      return null; //this section will be rendered as a child of its parent
    }

    return (
      <AutumnVStack
        key={sectionName}
        width={sectionWidth}
        p={columns > 1 ? 'm' : 0}
        borderWidth={columns > 1 ? 1 : 0}
        borderColor={'gray'}
        borderRadius={4}
      >
        <AutumnFormSectionElement<T>
          sectionName={sectionName}
          sectionConfig={sectionConfig}
          allSections={sections ?? []}
          sectionWidth={sectionWidth - (columns > 1 ? 30 : 0)}
          elements={filteredElements}
        />
      </AutumnVStack>
    );
  });

  const pressedOnDelete = () => {
    if (onDelete) {
      onDelete(state as T);
    }
  };

  if (!initialValues) return <AutumnLoader />;

  return (
    <InternalFormContext.Provider
      value={{
        validationStatus,
        validationMap,
        validationSetUp: !validation && !initialValues,
        loading,
        onChange,
        state: state as Record<string, never>,
        clickedSubmit,
      }}
    >
      <AutumnBox
        flex={1}
        onLayout={(event) => {
          if (Platform.OS === 'web') {
            // @ts-expect-error this actually works fine
            event.nativeEvent.target.measure((_x, _y, width) => {
              setWidth(width);
            });
          } else {
            event.target.measure((_x, _y, width) => {
              if (width > 0) {
                setWidth(width);
              }
            });
          }
        }}
      >
        {width != undefined && (
          <AutumnVStack space={'m'}>
            <AutumnHStack space={'m'} flexWrap={'wrap'}>
              {formSections}
            </AutumnHStack>

            {error && <AutumnAlert message={error} type={'error'} />}

            {!online && <AutumnAlert icon={faWifiSlash} message={'FormOffline'} type={'info'} />}

            <AutumnHStack space={'m'}>
              {onSubmit && (
                <AutumnBox flex={1}>
                  <Button
                    onPress={pressedOnSubmit}
                    localeKey={buttonTitle ?? 'Save'}
                    isLoading={loading}
                    disabled={disabled || !online || (validation && !validationStatus)}
                  />
                </AutumnBox>
              )}

              {onDelete && (
                <AutumnBox flex={1}>
                  <AutumnButton
                    localeKey={'Delete'}
                    variant={'danger'}
                    onPress={pressedOnDelete}
                    isLoading={loading}
                    disabled={disabled || !online}
                  />
                </AutumnBox>
              )}
            </AutumnHStack>
          </AutumnVStack>
        )}
      </AutumnBox>
    </InternalFormContext.Provider>
  );
}
